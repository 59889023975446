import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'innogy-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
  @Input()
  margin = true;

  @Input()
  biggerMargin = false;

  @Input()
  padding = true;

  @Input()
  transparent = false;

  @Input()
  hideFirstBorder = false;

  @Input()
  hideHeaderPaddingTop = false;

  @Input()
  smallHeader = false;
}
