import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export enum StatusSystemIconType {
  SUCCESS = 'pin-operative',
  WARNING = 'pin-occupied',
  ERROR = 'pin-unknown-error',
  UNDEFINED = 'pin-undefined',
  UNAVAILBLE = 'pin-unavailble',
  RESERVED = 'pin-reserved',
  PREPARING = 'pin-preparing',
  OPERATIVE = 'pin-operative',
  OCCUPIED = 'pin-occupied',
  NOHEARTBEAT = 'pin-noHeartbeat',
  NO_RESPONSE = 'pin-no-response',
  NEW = 'pin-new',
  PIN_WARNING = 'pin-warning',
  UNREACHABLE = 'pin-unreachable',
  UNKNOWN_ERROR = 'pin-unknown-error',
  SUSPENDED = 'pin-suspended',
  PIN_SUCCESS = 'pin-success',
  INVALID_RESPONSE = 'pin-invalid-response',
  INOPERATIVE = 'pin-inoperative',
  FINISHING = 'pin-finishing',
  FAULTED = 'pin-faulted',
  PIN_ERROR = 'pin-error',
  PIN_DISABLED = 'pin-disabled',
  CHARGE = 'pin-charge',
}

export interface StatusSystemIconInstance {
  name: string;
  color: string;
  defaultShape?: string;
}

export const statusSystemIconSettings: StatusSystemIconInstance[] = [
  { name: 'pin-undefined', color: 'disabled', defaultShape: 'rounded' },
  { name: 'pin-unavailble', color: 'disabled', defaultShape: 'rounded' },
  { name: 'pin-reserved', color: 'warning', defaultShape: 'rounded' },
  { name: 'pin-preparing', color: 'info', defaultShape: 'rounded' },
  { name: 'pin-operative', color: 'success', defaultShape: 'rounded' },
  { name: 'pin-occupied', color: 'warning', defaultShape: 'rounded' },
  { name: 'pin-noHeartbeat', color: 'disabled', defaultShape: 'rounded' },
  { name: 'pin-no-response', color: 'error', defaultShape: 'rounded' },
  { name: 'pin-new', color: 'disabled', defaultShape: 'rounded' },
  { name: 'pin-warning', color: 'warning', defaultShape: 'without-background' },
  { name: 'pin-unreachable', color: 'error', defaultShape: 'rounded' },
  { name: 'pin-unknown-error', color: 'error', defaultShape: 'rounded' },
  { name: 'pin-suspended', color: 'warning', defaultShape: 'rounded' },
  { name: 'pin-success', color: 'success', defaultShape: 'without-background' },
  { name: 'pin-invalid-response', color: 'error', defaultShape: 'rounded' },
  { name: 'pin-inoperative', color: 'disabled', defaultShape: 'rounded' },
  { name: 'pin-finishing', color: 'warning', defaultShape: 'rounded' },
  { name: 'pin-faulted', color: 'error', defaultShape: 'rounded' },
  { name: 'pin-error', color: 'error', defaultShape: 'without-background' },
  { name: 'pin-disabled', color: 'disabled', defaultShape: 'without-background' },
  { name: 'pin-charge', color: 'info', defaultShape: 'rounded' },
];

@Component({
  selector: 'eop-status-system-icon',
  templateUrl: './status-system-icon.component.html',
  styleUrls: ['./status-system-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusSystemIconComponent implements OnInit {
  instance: StatusSystemIconInstance;

  @Input()
  type: string;

  @Input()
  tooltip: string;

  @Input()
  iconSize = 'small';

  ngOnInit() {
    let searchKey = this.type;
    if (!Object.values(StatusSystemIconType).includes(this.type as StatusSystemIconType)) {
      searchKey = StatusSystemIconType[this.type.toUpperCase()];
    }
    this.instance = statusSystemIconSettings.find(setting => setting.name === searchKey);
  }

  get styles(): string {
    if (this.instance) {
      const defaultShapeStyle = 'status-system-icon-' + this.instance.defaultShape;
      const colorStyle = 'status-system-icon-' + this.instance.color;
      const iconStyle = 'icon-bold-' + this.instance.name;
      const iconSize = 'icon-' + this.iconSize;
      return (
        iconSize +
        ' ' +
        iconStyle +
        ' ' +
        colorStyle +
        (this.instance.defaultShape ? ' ' + defaultShapeStyle : ' ')
      );
    }
    return '';
  }
}
