import { Component, Input } from '@angular/core';
import { IconColor, IconSize } from '@widgets/eop-icon';

export enum WizardFinishStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

@Component({
  selector: 'wizard-finish-banner',
  templateUrl: 'wizard-finish-banner.component.html',
  styleUrls: ['wizard-finish-banner.component.scss'],
})
export class WizardFinishBannerComponent {
  protected readonly IconColor = IconColor;
  protected readonly IconSize = IconSize;

  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  wizardFinishStatus: WizardFinishStatus;
  protected readonly WizardFinishStatus = WizardFinishStatus;
}
