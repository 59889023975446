export enum EPermissions {
  /*
   * Admin
   */
  MANAGE_GLOBAL_USER_MANAGEMENT = 'MANAGE_GLOBAL_USER_MANAGEMENT',
  MANAGE_LOGIN_MESSAGES = 'MANAGE_LOGIN_MESSAGES',
  MANAGE_POI_SERVICE_PLATFORM_PROV = 'MANAGE_POI_SERVICE_PLATFORM_PROV',
  MANAGE_PROFILES = 'MANAGE_PROFILES',
  MANAGE_ROAMING_RELATIONSHIPS = 'MANAGE_ROAMING_RELATIONSHIPS',
  MANAGE_STATIONMANAGEMENT_MASTERDATA = 'MANAGE_STATIONMANAGEMENT_MASTERDATA',
  MANAGE_WITH_NO_TARIFF_RESTRICTIONS = 'MANAGE_WITH_NO_TARIFF_RESTRICTIONS',
  VIEW_DIRECT_PAYMENT_ADMIN = 'VIEW_DIRECT_PAYMENT_ADMIN',
  VIEW_GLOBAL_USER_MANAGEMENT = 'VIEW_GLOBAL_USER_MANAGEMENT',
  VIEW_PROFILES = 'VIEW_PROFILES',
  VIEW_ROAMING_RELATIONSHIPS = 'VIEW_ROAMING_RELATIONSHIPS',
  VIEW_STATIONMANAGEMENT_MASTERDATA = 'VIEW_STATIONMANAGEMENT_MASTERDATA',

  /*
   * Global
   */
  AUTOMATED_PASSWORD_UPDATE = 'AUTOMATED_PASSWORD_UPDATE',
  DELETE_CHARGINGSTATION = 'DELETE_CHARGINGSTATION',
  MANAGE_APPROVE_BDR_CORRECTION = 'MANAGE_APPROVE_BDR_CORRECTION',
  MANAGE_BDR_CORRECTION = 'MANAGE_BDR_CORRECTION',
  MANAGE_CHARGINGSTATION_MASTERDATA = 'MANAGE_CHARGINGSTATION_MASTERDATA',
  MANAGE_CLH_TARIFFS = 'MANAGE_CLH_TARIFFS',
  MANAGE_CLIENT_USER_MANAGEMENT = 'MANAGE_CLIENT_USER_MANAGEMENT',
  MANAGE_CONTRACTS = 'MANAGE_CONTRACTS',
  MANAGE_CONTRACTS_EMARKETPLACE = 'MANAGE_CONTRACTS_EMARKETPLACE',
  MANAGE_CPO_ROAMING = 'MANAGE_CPO_ROAMING',
  MANAGE_CPO_TARIFFS_DIFFERENT_CURRENCY = 'MANAGE_CPO_TARIFFS_DIFFERENT_CURRENCY',
  MANAGE_CPO_TARIFFS_ASSIGN_CURRENCY_OVERRIDE = 'MANAGE_CPO_TARIFFS_ASSIGN_CURRENCY_OVERRIDE',
  MANAGE_CPO_TARIFFS = 'MANAGE_CPO_TARIFFS',
  MANAGE_CPO_TARIFF_ASSIGNMENT = 'MANAGE_CPO_TARIFF_ASSIGNMENT',
  MANAGE_CPO_USERCHARGING_TARIFFS = 'MANAGE_CPO_USERCHARGING_TARIFFS',
  MANAGE_CREATE_CHARGINGSTATION = 'MANAGE_CREATE_CHARGINGSTATION',
  MANAGE_CS_IBET_FLOW = 'MANAGE_CS_IBET_FLOW',
  MANAGE_DECLINE_BDR_CORRECTION = 'MANAGE_DECLINE_BDR_CORRECTION',
  MANAGE_DIRECT_PAYMENT_SETTINGS = 'MANAGE_DIRECT_PAYMENT_SETTINGS',
  MANAGE_EMAID_GROUPS = 'MANAGE_EMAID_GROUPS',
  MANAGE_EMP_EXTERNAL_ROAMING = 'MANAGE_EMP_EXTERNAL_ROAMING',
  MANAGE_EMP_ROAMING = 'MANAGE_EMP_ROAMING',
  MANAGE_EMP_TARIFFS = 'MANAGE_EMP_TARIFFS',
  MANAGE_EMP_TARIFF_ASSIGNMENT = 'MANAGE_EMP_TARIFF_ASSIGNMENT',
  MANAGE_ESOLUTION_EMP_TARIFFS = 'MANAGE_ESOLUTION_EMP_TARIFFS',
  MANAGE_IMPORT_CHARGINGSTATION = 'MANAGE_IMPORT_CHARGINGSTATION',
  MANAGE_INVITED_USERCHARGING_EVDRIVER = 'MANAGE_INVITED_USERCHARGING_EVDRIVER',
  MANAGE_OCPP_MASS_CONFIGURATION = 'MANAGE_OCPP_MASS_CONFIGURATION',
  MANAGE_PAYMENT_TERMINALS = 'MANAGE_PAYMENT_TERMINALS',
  MANAGE_PAYMENT_TERMINALS_PRE_AUTH_AMOUNT = 'MANAGE_PAYMENT_TERMINALS_PRE_AUTH_AMOUNT',
  MANAGE_PRODUCT_ASSIGNMENT = 'MANAGE_PRODUCT_ASSIGNMENT',
  MANAGE_ROAMING_OFFERS = 'MANAGE_ROAMING_OFFERS',
  MANAGE_SELECTIVE_CHARGING_FILTERS = 'MANAGE_SELECTIVE_CHARGING_FILTERS',
  MANAGE_SUB_BUSINESS_PARTNER = 'MANAGE_SUB_BUSINESS_PARTNER',
  MANAGE_USER_PROFIL_PERSONAL_SETTINGS = 'MANAGE_USER_PROFIL_PERSONAL_SETTINGS',
  MANAGE_USERCHARGING_CONFIGURATION = 'MANAGE_USERCHARGING_CONFIGURATION',
  MANAGE_USERCHARGING_FLAG = 'MANAGE_USERCHARGING_FLAG',
  MANAGE_VOUCHERS = 'MANAGE_VOUCHERS',
  MANAGE_VOUCHER_CPO = 'MANAGE_VOUCHER_CPO',
  UNINSTALL_CHARGINGSTATION = 'UNINSTALL_CHARGINGSTATION',
  VIEW_ACTIVATIONOVERVIEW = 'VIEW_ACTIVATIONOVERVIEW',
  VIEW_BDR = 'VIEW_BDR',
  VIEW_BDR_CORRECTION = 'VIEW_BDR_CORRECTION',
  VIEW_CONTRACTS_EMARKETPLACE = 'VIEW_CONTRACTS_EMARKETPLACE',
  VIEW_CHARGING_SESSIONS = 'VIEW_CHARGING_SESSIONS',
  VIEW_CHARGINGDATA_CPO = 'VIEW_CHARGINGDATA_CPO',
  VIEW_CHARGINGDATA_EMP = 'VIEW_CHARGINGDATA_EMP',
  VIEW_CHARGINGSTATION_MASTERDATA = 'VIEW_CHARGINGSTATION_MASTERDATA',
  VIEW_CHARGINGSTATION_DETAILS = 'VIEW_CHARGINGSTATION_DETAILS',
  VIEW_CLH_TARIFFS = 'VIEW_CLH_TARIFFS',
  VIEW_CLIENT_USER_MANAGEMENT = 'VIEW_CLIENT_USER_MANAGEMENT',
  VIEW_CONTRACTS = 'VIEW_CONTRACTS',
  VIEW_CPO_TARIFFS = 'VIEW_CPO_TARIFFS',
  VIEW_CPO_TARIFF_ASSIGNMENT = 'VIEW_CPO_TARIFF_ASSIGNMENT',
  VIEW_CPO_USERCHARGING_TARIFFS = 'VIEW_CPO_USERCHARGING_TARIFFS',
  VIEW_DIRECT_PAYMENT_EMAIDS = 'VIEW_DIRECT_PAYMENT_EMAIDS',
  VIEW_DIRECT_PAYMENT_SETTINGS = 'VIEW_DIRECT_PAYMENT_SETTINGS',
  VIEW_EMP_TARIFFS = 'VIEW_EMP_TARIFFS',
  VIEW_EMP_TARIFF_ASSIGNMENT = 'VIEW_EMP_TARIFF_ASSIGNMENT',
  VIEW_EMAID_GROUPS = 'VIEW_EMAID_GROUPS',
  VIEW_ESOLUTION_EMP_TARIFFS = 'VIEW_ESOLUTION_EMP_TARIFFS',
  VIEW_EXTENDED_OVERVIEW = 'VIEW_EXTENDED_OVERVIEW',
  VIEW_IMPORT_CHARGINGSTATION = 'VIEW_IMPORT_CHARGINGSTATION',
  VIEW_INCREASE_REVENUE = 'VIEW_INCREASE_REVENUE',
  VIEW_INVITED_USERCHARGING_EVDRIVER = 'VIEW_INVITED_USERCHARGING_EVDRIVER',
  VIEW_OCPP_MASS_CONFIGURATION = 'VIEW_OCPP_MASS_CONFIGURATION',
  VIEW_PAYMENT_TERMINAL_EMAIDS = 'VIEW_PAYMENT_TERMINAL_EMAIDS',
  VIEW_PAYMENT_TERMINALS = 'VIEW_PAYMENT_TERMINALS',
  VIEW_PRODUCTS = 'VIEW_PRODUCTS',
  VIEW_PRODUCT_ASSIGNMENT = 'VIEW_PRODUCT_ASSIGNMENT',
  VIEW_REVENUE_REPORTING_CPO = 'VIEW_REVENUE_REPORTING_CPO',
  VIEW_REVENUE_REPORTING_EMP = 'VIEW_REVENUE_REPORTING_EMP',
  VIEW_ROAMING_OFFERS = 'VIEW_ROAMING_OFFERS',
  VIEW_SELECTIVE_CHARGING_FILTERS = 'VIEW_SELECTIVE_CHARGING_FILTERS',
  VIEW_SUB_BUSINESS_PARTNER = 'VIEW_SUB_BUSINESS_PARTNER',
  VIEW_USERCHARGING_CONFIGURATION = 'VIEW_USERCHARGING_CONFIGURATION',
  VIEW_VOUCHER_CPO = 'VIEW_VOUCHER_CPO',
  VIEW_QUICKSIGHT_REPORTS = 'VIEW_QUICKSIGHT_REPORTS',

  /*
   * Selective
   */
  ACTIVATE_SERVICE_CONTRACT = 'ACTIVATE_SERVICE_CONTRACT',
  ACTIVATE_SERVICE_COUPON = 'ACTIVATE_SERVICE_COUPON',
  ACTIVATE_SERVICE_GOODWILL = 'ACTIVATE_SERVICE_GOODWILL',
  MANAGE_AID = 'MANAGE_AID',
  MANAGE_ALERTING_CLIENT = 'MANAGE_ALERTING_CLIENT',
  MANAGE_AUTO_METER_DETECTION = 'MANAGE_AUTO_METER_DETECTION',
  MANAGE_CP_NOTE = 'MANAGE_CP_NOTE',
  MANAGE_CP_OPERATIVE_STATUS = 'MANAGE_CP_OPERATIVE_STATUS',
  MANAGE_CP_RESTART_FORCE = 'MANAGE_CP_RESTART_FORCE',
  MANAGE_CP_RESTART_REQUEST = 'MANAGE_CP_RESTART_REQUEST',
  MANAGE_CP_STOP_CHARGING = 'MANAGE_CP_STOP_CHARGING',
  MANAGE_CP_TROUBLESHOOTING = 'MANAGE_CP_TROUBLESHOOTING',
  MANAGE_CP_UNLOCK_CONNECTOR = 'MANAGE_CP_UNLOCK_CONNECTOR',
  MANAGE_CPO_EXTERNAL_ROAMING = 'MANAGE_CPO_EXTERNAL_ROAMING',
  MANAGE_CS_PROFESSIONAL = 'MANAGE_CS_PROFESSIONAL',
  MANAGE_DIRECT_PAYMENT_FLAG = 'MANAGE_DIRECT_PAYMENT_FLAG',
  MANAGE_FIRMWARE = 'MANAGE_FIRMWARE',
  MANAGE_INSTALLATION_DATE = 'MANAGE_INSTALLATION_DATE',
  MANAGE_LG2WAN_CONFIGURATION = 'MANAGE_LG2WAN_CONFIGURATION',
  MANAGE_PAYMENT_TERMINAL_STATIONS = 'MANAGE_PAYMENT_TERMINAL_STATIONS',
  MANAGE_TOUCH_STATION = 'MANAGE_TOUCH_STATION',
  MANAGE_LOADMANAGEMENT = 'MANAGE_LOADMANAGEMENT',
  MANAGE_LOCAL_LOADMANAGEMENT = 'MANAGE_LOCAL_LOADMANAGEMENT',
  MANAGE_LOCATION_MANAGEMENT = 'MANAGE_LOCATION_MANAGEMENT',
  MANAGE_OCPP_CONFIGURATION = 'MANAGE_OCPP_CONFIGURATION',
  MANAGE_OPENINGTIMES = 'MANAGE_OPENINGTIMES',
  MANAGE_PLATFORM_HOTLINE_NUMBER = 'MANAGE_PLATFORM_HOTLINE_NUMBER',
  MANAGE_SINGLE_FIRMWARE = 'MANAGE_SINGLE_FIRMWARE',
  MANAGE_STATION_PROVIDER = 'MANAGE_STATION_PROVIDER',
  MANAGE_SUBSETTINGS_QUICKCONFIG = 'MANAGE_SUBSETTINGS_QUICKCONFIG',
  MANAGE_UPDATE_CP_STATUS = 'MANAGE_UPDATE_CP_STATUS',
  MANAGE_VIRTUAL_STATION = 'MANAGE_VIRTUAL_STATION',
  VIEW_AID = 'VIEW_AID',
  VIEW_ALERTING_CLIENT = 'VIEW_ALERTING_CLIENT',
  VIEW_ASSIGN_TARIFF_VALIDATION_HINT = 'VIEW_ASSIGN_TARIFF_VALIDATION_HINT',
  VIEW_CP_NOTE = 'VIEW_CP_NOTE',
  VIEW_CP_TECHNICAL_LOGS_LSG = 'VIEW_CP_TECHNICAL_LOGS_LSG',
  VIEW_CP_TECHNICAL_LOGS_OCPP = 'VIEW_CP_TECHNICAL_LOGS_OCPP',
  VIEW_FIRMWARE = 'VIEW_FIRMWARE',
  VIEW_LG2WAN_CONFIGURATION = 'VIEW_LG2WAN_CONFIGURATION',
  VIEW_LOCAL_LOADMANAGEMENT = 'VIEW_LOCAL_LOADMANAGEMENT',
  VIEW_LOADMANAGEMENT = 'VIEW_LOADMANAGEMENT',
  VIEW_LOCATION_MANAGEMENT = 'VIEW_LOCATION_MANAGEMENT',
  VIEW_OCPP_COMMUNICATION_LOGS = 'VIEW_OCPP_COMMUNICATION_LOGS',
  VIEW_OCPP_CONFIGURATION = 'VIEW_OCPP_CONFIGURATION',
  VIEW_OPENINGTIMES = 'VIEW_OPENINGTIMES',
  VIEW_PAYMENT_TERMINAL_STATIONS = 'VIEW_PAYMENT_TERMINAL_STATIONS',
  VIEW_SINGLE_FIRMWARE = 'VIEW_SINGLE_FIRMWARE',
  VIEW_STATION_SERVICE_LINK = 'VIEW_STATION_SERVICE_LINK',
  VIEW_SUBSETTINGS_QUICKCONFIG = 'VIEW_SUBSETTINGS_QUICKCONFIG',
}

export interface ISettings {
  locale?: Language;
  timezone?: string;
  datePattern?: string;
  timePattern?: string;
  decimalSeparator?: string;
  csvExportSeparator?: string;
  storeFrontendSession?: boolean;
  csvExportFieldDelimiter?: string;
}

export interface IUserLogin {
  id: number;
  userId: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  internalRole: boolean;
  visualStyleId: string;
  settings: ISettings;
  permissions: EPermissions[];
}

export interface IPasswordUpdate {
  oldPassword?: string;
  newPassword?: string;
}

export interface IPasswordAssignment {
  password?: string;
}

export interface IAccountSettingValueLists {
  csvSeparators?: IKeyValue[];
  csvTextQualifiers?: IKeyValue[];
  dateFormats?: IKeyValue[];
  numberFormats?: IKeyValue[];
  timeFormats?: IKeyValue[];
}

export const passwordValidationRegex = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-+]).{12,50}$/m
);

export const emailValidationRegex = new RegExp(
  /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/m
);

export enum Language {
  Danish = 'da',
  German = 'de',
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  Hungarian = 'hu',
  Italian = 'it',
  Dutch = 'nl',
  Swedish = 'sv',
  Null = '',
  Empty = 'EMPTY',
}

export interface IKeyValue {
  key: string;
  value: string;
}
