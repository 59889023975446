import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { ImagePlaceholderConfiguration } from '@widgets/eop-image-placeholder/eop-image-placeholder.interface';
import {
  ChipLiteColor,
  ChipLiteSize,
} from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';
import { ChipColor } from '@widgets/innogy-chips/chipColor';
import { IChipsData } from '@widgets/innogy-chips/chipsData.interface';
import {
  DataTableRow,
  HighlightedColor,
} from '@widgets/innogy-data-table/data/data-table-row.data';
import { PaginationComponent } from '@widgets/pagination/pagination.component';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';
import produce from 'immer';
import { unionBy, xorBy } from 'lodash-es';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InputChangedData } from './tableDefinition/column.interface';
import { TableSortSettings } from './tableDefinition/table-settings.interface';
import { ITableConfig } from './tableDefinition/tableConfig.interface';
import { ITableData } from './tableDefinition/tableData.interface';

@Component({
  selector: 'app-innogy-data-table',
  templateUrl: './innogy-data-table.component.html',
  styleUrls: ['./innogy-data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnogyDataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  useVirtualScroll = false;

  @Input()
  dataSource: ITableData;

  @Input()
  tableConfig: ITableConfig;

  // Compatibility for old data
  @Input()
  checkedRows: { [key: string]: any };

  @Input()
  loadingData = false;

  @Input()
  contentPlaceholder: ImagePlaceholderConfiguration;

  @Input()
  selectedRows: DataTableRow[] = [];

  @Input()
  expandedRow: DataTableRow;

  @Input()
  tableDetailTemplate: TemplateRef<any>;

  @Input()
  detailListExpandableRow = false;

  @ViewChild('tableElementReference', { static: true })
  private tableElementReference: ElementRef;

  @ViewChild('searchphrase')
  searchInput: ElementRef;

  @ViewChild('paginator')
  private paginator: PaginationComponent;

  @ViewChild('tableBodyElementReference')
  private tableBodyElementReference: ElementRef;

  @Output()
  toggleStateChanged = new EventEmitter<DataTableRow>();

  @Output()
  requireNewData = new EventEmitter<{ page: number; pageSize?: number }>();

  @Output()
  sortData = new EventEmitter<TableSortSettings>();

  @Output()
  deleteData = new EventEmitter<any>();

  @Output()
  customActionData = new EventEmitter<any>();

  @Output()
  customAction2Data = new EventEmitter<any>();

  @Output()
  editData = new EventEmitter<any>();

  @Output()
  rowClicked = new EventEmitter<DataTableRow>();

  @Output()
  searchData = new EventEmitter<string>();

  @Output()
  selectionData = new EventEmitter<string[]>();

  @Output()
  selectionDataFull = new EventEmitter<DataTableRow[]>();

  @Output()
  inputChanged = new EventEmitter<InputChangedData>();

  rows: DataTableRow[] = [];
  private selectedRowsOnPage: DataTableRow[] = [];

  toggleState: string = 'DISABLED';
  uniqueKey: string;
  chipsData: IChipsData[] = [];
  pageSize = 15;
  pageSizeOptions = [15, 50, 100];
  currentPage = 0;
  nextDataSelection = 'paginator';
  date = Date;

  displayedColumns: any[];
  detailColumns: any[];
  mapper: {};
  expandedElement: number;
  tableHeightStyle: {};
  searchVisible = false;
  chipsColor = ChipColor.DEFAULT;
  chipsHeadline: string = null;
  hover: number = null;
  sortState: TableSortSettings = { column: '', direction: '' };
  maxSelectedItems: number;
  singleSelectMode: boolean;
  placeHolderString = this.translate.instant('GLOBAL.SEARCH_FOR') + ' ';
  placeHolderTitle: string;

  PLACEHOLDER_MAX_LENGTH = 68;

  tableColumns = [];

  tableIsSortable: boolean;
  hasExpandableRows = false;

  private searchInput$: Subject<string> = new Subject<string>();
  private inViewPort = false;

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  readonly IconSize = IconSize;
  readonly ChipLiteSize = ChipLiteSize;
  readonly ChipLiteColor = ChipLiteColor;

  constructor(
    public cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.preparePlaceholder();
    this.doTableStyling();
    this.getDisplayedColumns();

    this.searchInput$.pipe(debounceTime(500)).subscribe(searchText => {
      this.expandedRow = null;
      this.searchData.emit(searchText.trim());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableConfig'] && this.tableConfig) {
      this.uniqueKey = this.tableConfig.tableSettings.uniqueKey;

      this.getDisplayedColumns();
    }

    if (changes['dataSource'] && this.dataSource) {
      // Compatibility for old data
      if (this.dataSource.data && this.dataSource.data.length > 0) {
        const rows: DataTableRow[] = [];
        this.dataSource.data.forEach(data => {
          // Convert raw data into DataTableRow
          const id = this.uniqueKey ? String(data[this.uniqueKey]) : null;
          rows.push(
            new DataTableRow(
              id,
              data,
              data.disabled,
              data.highlightedColor ? data.highlightedColor : HighlightedColor.NONE
            )
          );
        });
        this.rows = rows;
      } else {
        this.rows = this.dataSource.rows || [];
      }

      this.selectedRowsOnPage = this.selectedRows.filter(
        selectedRow => !!this.rows.find(row => selectedRow.id === row.id)
      );

      if (!changes['dataSource'].firstChange) {
        // Backend returns the paging values as strings
        if (Number(this.dataSource.paging.currentPage) === 0) {
          const table = this.tableElementReference.nativeElement;
          table.scrollTop = 0;
          this.currentPage = 1;

          if (this.paginator && this.paginator.pageIndex > 0) {
            this.paginator.pageIndex = 0;
            this.paginator.selectPage('1');
          }
        }
      }

      // Expandable
      this.handleExpandableRows();
    }

    if (changes['expandedRow'] || changes['dataSource']) {
      if (this.expandedRow && this.expandedRow.id) {
        this.expandedElement = this.rows.findIndex(row => row.id === this.expandedRow.id);
      } else {
        this.expandedElement = null;
      }
    }

    if (changes['checkedRows'] && this.checkedRows) {
      // Compatibility for old data
      this.resetSelection();
      Object.entries(this.checkedRows)
        .filter(([key, value]) => !!value)
        .forEach(([key]) => {
          const placeholder = new DataTableRow(key, { placeholder: true });
          this.selectedRows.push(placeholder);
          if (this.rows.find(row => row.id === key)) {
            // Only add to page selection if row is also currently visible
            this.selectedRowsOnPage.push(placeholder);
          }
          if (this.tableConfig.tableSettings.showChipList) {
            this.addChips(placeholder);
          }
        });
      this.cdr.detectChanges();
    }

    if (changes['selectedRows'] && this.selectedRows) {
      const rows = this.selectedRows;
      this.resetSelection();
      this.selectedRows = rows;
      this.selectedRows.forEach(row => {
        const key = row.id;
        const placeholder = new DataTableRow(key, { placeholder: true });
        if (this.rows.find(row => row.id === key)) {
          // Only add to page selection if row is also currently visible
          this.selectedRowsOnPage.push(placeholder);
        }
        if (this.tableConfig.tableSettings.showChipList) {
          this.addChips(placeholder);
        }
      });
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.dataSource && !this.dataSource.data && !this.dataSource.rows) {
      this.getData();
    }
  }

  setToggleState(toggleState: string): boolean {
    if (toggleState === 'ACTIVE' || toggleState === 'INACTIVE') {
      this.toggleState = toggleState;
      return true;
    } else {
      this.toggleState = 'DISABLED';
      return false;
    }
  }

  toggle(row: DataTableRow) {
    this.toggleStateChanged.emit(row);
  }

  private preparePlaceholder() {
    const placeholders = this.tableConfig.tableSettings.searchPlaceholder;
    if (placeholders && placeholders.length > 0) {
      placeholders.forEach((item, index) => {
        if (index < placeholders.length - 1 && index + 1 !== placeholders.length - 1) {
          this.placeHolderString = this.placeHolderString.concat(item + ', ');
        } else if (index === placeholders.length - 1 && placeholders.length !== 1) {
          this.placeHolderString = this.placeHolderString.concat(
            this.translate.instant('GLOBAL.OR') + ' ' + item
          );
        } else {
          this.placeHolderString = this.placeHolderString.concat(item + ' ');
        }
      });
    } else {
      this.placeHolderString = '';
    }
    this.placeHolderTitle = this.placeHolderString;
    this.checkPlaceholderLength();
  }

  private checkPlaceholderLength() {
    if (this.placeHolderString.length > this.PLACEHOLDER_MAX_LENGTH) {
      this.placeHolderString = this.placeHolderString.slice(0, this.PLACEHOLDER_MAX_LENGTH) + '...';
    }
  }

  private doTableStyling() {
    if (this.tableConfig.tableSettings) {
      // Set max height
      if (this.tableConfig.tableSettings.height) {
        this.tableHeightStyle = {
          'max-height': this.tableConfig.tableSettings.height,
          height: this.tableConfig.tableSettings.height,
        };
      }
      if (
        this.tableConfig.tableSettings.pageSizeOptions &&
        this.tableConfig.tableSettings.pageSizeOptions.length > 0
      ) {
        this.pageSizeOptions = this.tableConfig.tableSettings.pageSizeOptions;
      }
      if (this.tableConfig.tableSettings.initPageSize) {
        this.pageSize = this.tableConfig.tableSettings.initPageSize;
      }
      // Paging or Infinite Scrolling
      if (this.tableConfig.tableSettings.nextDataSelection) {
        this.nextDataSelection = this.tableConfig.tableSettings.nextDataSelection;
      }

      // Sortable
      this.tableIsSortable = this.tableConfig.tableSettings.sortable;
      this.sortState = this.tableConfig.tableSettings.defaultSort || { column: '', direction: '' };

      // Checkable - Unique Key is required
      if (this.tableConfig.tableSettings.checkable && this.tableConfig.tableSettings.uniqueKey) {
        this.uniqueKey = this.tableConfig.tableSettings.uniqueKey;

        // showChipList - chipsText is required
        if (
          this.tableConfig.tableSettings.showChipList &&
          this.tableConfig.tableSettings.chipText
        ) {
          if (this.tableConfig.tableSettings.chipColor) {
            this.chipsColor = this.tableConfig.tableSettings.chipColor;
          }
          if (this.tableConfig.tableSettings.chipsHeadline) {
            this.chipsHeadline = this.tableConfig.tableSettings.chipsHeadline;
            this.cdr.detectChanges();
          }
        }
      }

      // Editable
      if (this.tableConfig.tableSettings.editable) {
        this.tableColumns.push('tableEdit');
      }

      // Deletable
      if (this.tableConfig.tableSettings.deletable) {
        this.tableColumns.push('tableDelete');
      }

      // Expandable
      this.handleExpandableRows();

      // Dummy Column to fix scrollbar style bug
      if (this.tableConfig.tableSettings.nextDataSelection === 'scroll') {
        this.tableColumns.push('tableScroll');
      }

      if (this.tableConfig.tableSettings.maxSelectedItems) {
        this.maxSelectedItems = this.tableConfig.tableSettings.maxSelectedItems;
      }

      this.singleSelectMode = this.maxSelectedItems === 1;

      this.cdr.markForCheck();
    }
  }

  private getDisplayedColumns() {
    let displayedColumns = [];
    const detailColumns = [];

    if (this.tableConfig.tableSettings.checkable && this.uniqueKey) {
      displayedColumns.push('tableSelect');
    }
    let mapperObj = {};
    this.tableConfig.columns.forEach(function (elem, index) {
      if (elem['visibleInHeader']) {
        const keyName = elem['id'];
        const columnIndex = {};
        columnIndex[keyName] = index;
        mapperObj = Object.assign(mapperObj, columnIndex);
        displayedColumns.push(elem['id']);
      }
      if (elem['visibleInDetails']) {
        const keyName = elem['id'];
        const columnIndex = {};
        columnIndex[keyName] = index;
        mapperObj = Object.assign(mapperObj, columnIndex);
        detailColumns.push(elem['id']);
      }
    });

    this.mapper = mapperObj;
    displayedColumns = displayedColumns.concat(this.tableColumns);

    this.displayedColumns = displayedColumns;
    this.detailColumns = detailColumns;
  }

  toggleSearch() {
    this.searchVisible = !this.searchVisible;
    if (this.searchVisible) {
      this.cdr.detectChanges(); // Needs to trigger change detection before focusing
      this.searchInput.nativeElement.focus();
    }
  }

  hideSearch() {
    this.searchVisible = false;
  }

  onRowClicked(row: DataTableRow, index: number) {
    if (this.tableConfig.tableSettings.expandable || row.expandable) {
      if (this.expandedElement === index) {
        this.expandedElement = null;
        this.expandedRow = null;
      } else {
        this.expandedElement = index;
        this.expandedRow = row;
        this.rowClicked.emit(row);
      }
    }
    if (this.tableConfig.tableSettings.clickableRow) {
      this.rowClicked.emit(row);
    }
  }

  // Used for Paginator
  onPaginateChange(paginator: PageEvent) {
    this.pageSize = paginator.pageSize;
    const event = {
      page: paginator.pageIndex,
      pageSize: paginator.pageSize,
    };
    this.requireNewData.emit(event);
  }

  // Used for infinite scroll
  getData() {
    const event = {
      page: this.currentPage++,
    };
    this.requireNewData.emit(event);
  }

  private nextPageAvailable(): boolean {
    return (
      Number(this.dataSource.paging.currentPage) < Number(this.dataSource.paging.totalPages) - 1 ||
      this.dataSource.paging.nextPageAvailable
    );
  }

  onTableScroll(event) {
    if (
      !this.loadingData &&
      this.nextDataSelection === 'scroll' &&
      this.nextPageAvailable() &&
      event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight) < 200
    ) {
      this.getData();
    }
  }

  onViewScroll(event) {
    if (this.nextPageAvailable()) {
      if (event === true && this.inViewPort === false) {
        this.inViewPort = true;
        this.getData();
      } else if (!this.loadingData && event === false) {
        this.inViewPort = false;
      }
    }
  }

  resetTableScroll() {
    const bodyElement = this.tableBodyElementReference.nativeElement;
    if (bodyElement) {
      bodyElement.scrollTop = 0;
    }
  }

  requestSort(column: string) {
    if (this.tableIsSortable) {
      if (this.sortState.column === column) {
        if (this.sortState.direction === '' || this.sortState.direction === 'ASC') {
          this.sortState.direction = 'DESC';
        } else {
          this.sortState.direction = 'ASC';
        }
      } else {
        this.sortState = { column: column, direction: 'DESC' };
      }

      this.expandedRow = null;

      this.sortData.emit(this.sortState);
    }
  }

  requestDataSearch(searchText: string) {
    this.searchInput$.next(searchText);
  }

  deleteDataSource(row: DataTableRow) {
    this.deleteData.emit(row.data);
  }

  customActionDataSource(row: DataTableRow) {
    this.customActionData.emit(row.data);
  }

  customAction2DataSource(row: DataTableRow) {
    this.customAction2Data.emit(row.data);
  }

  editDataSource(row: DataTableRow) {
    this.editData.emit(row.data);
  }

  onInputChanged(row: DataTableRow, newValue: string) {
    const inputValue: InputChangedData = {
      id: row.id,
      newValue: newValue,
    };
    this.inputChanged.emit(inputValue);
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
  }

  /**
   * To be used on a @ViewChild to manually reset the selected rows via the parent
   */
  resetSelection() {
    this.selectedRows = [];
    this.selectedRowsOnPage = [];
    this.chipsData = [];
    this.cdr.detectChanges();
  }

  isSelected(row: DataTableRow): boolean {
    return !!this.selectedRows.find(sr => sr.id === row.id);
  }

  toggleSelectDisabled(row: DataTableRow) {
    if (this.isSelected(row)) {
      return false;
    } else {
      return this.maxSelectedItems ? this.selectedRows.length >= this.maxSelectedItems : false;
    }
  }

  toggleSelect(row: DataTableRow) {
    if (row.id == null) {
      throw new Error('You are trying to select a row without having set the id attribute');
    }

    if (this.isSelected(row)) {
      this.selectedRows = this.removeRow(this.selectedRows, row.id);
      this.selectedRowsOnPage = this.removeRow(this.selectedRowsOnPage, row.id);
      this.removeChips(row);
    } else {
      if (this.singleSelectMode) {
        this.selectedRows = [row];
        this.selectedRowsOnPage = [row];
        this.chipsData = [];
        this.addChips(row);
      } else {
        this.selectedRows.push(row);
        this.selectedRowsOnPage.push(row);
        this.addChips(row);
      }
    }

    this.selectionData.emit(this.selectedRows.map(sr => sr.id));
    this.selectionDataFull.emit(this.selectedRows);
  }

  toggleSelectAllDisabled() {
    return this.maxSelectedItems
      ? this.pageSize - this.selectedRowsOnPage.length + this.selectedRows.length >
          this.maxSelectedItems
      : false;
  }

  toggleSelectAll() {
    if (this.rows.some(row => row.id == null)) {
      throw new Error('You are trying to select a row without having set the id attribute');
    }

    if (this.hasAllSelected()) {
      this.selectedRows = this.selectedRows.filter(sr => !this.rows.find(r => sr.id === r.id));
      this.removeChips(...this.selectedRowsOnPage);
      this.selectedRowsOnPage = [];
    } else {
      this.selectedRows = this.addAllRowsToSelection(this.selectedRows);
      this.selectedRowsOnPage = this.addAllRowsToSelection(this.selectedRowsOnPage);
      this.addChips(...this.selectedRowsOnPage);
    }

    this.selectionData.emit(this.selectedRows.map(sr => sr.id));
    this.selectionDataFull.emit(this.selectedRows);
  }

  hasSomeSelected(): boolean {
    return (
      !this.hasAllSelected() &&
      this.selectedRowsOnPage.some(sr => this.rows.some(r => sr.id === r.id))
    );
  }

  hasAllSelected(): boolean {
    return (
      this.rows.filter(r => !r.disabled).length > 0 &&
      xorBy(
        this.selectedRowsOnPage,
        this.rows.filter(r => !r.disabled),
        'id'
      ).length === 0
    );
  }

  private addChips(...rows: DataTableRow[]) {
    this.chipsData = produce<IChipsData[]>(this.chipsData, draft => {
      const chips: IChipsData[] = [];
      rows.forEach(row => {
        chips.push({ value: row.id, name: row.id });
      });
      draft = unionBy(draft, chips, 'value');
      return draft;
    });
  }

  private removeChips(...rows: DataTableRow[]) {
    this.chipsData = produce<IChipsData[]>(this.chipsData, draft => {
      rows.forEach(row => {
        draft = draft.filter(d => d.value !== row.id);
      });
      return draft;
    });
  }

  removeChip(chip: IChipsData) {
    this.toggleSelect(this.selectedRows.find(sr => sr.id === chip.value));
  }

  trackLinkClick(column: string) {
    const category = this.tableConfig.tableSettings.trackingCategory;
    this.googleAnalyticsService.sendEvent(
      category ? category : GoogleAnalyticsCategory.TABLE,
      GoogleAnalyticsAction.NAVIGATE,
      column
    );
  }

  private removeRow(rows: DataTableRow[], id: string): DataTableRow[] {
    return rows.filter(row => row.id !== id);
  }

  private addAllRowsToSelection(rows: DataTableRow[]): DataTableRow[] {
    return unionBy(rows, this.rows, 'id').filter(row => !row.disabled);
  }

  private handleExpandableRows() {
    if (
      this.tableConfig.tableSettings.expandable ||
      this.rows.filter(r => r.expandable).length > 0
    ) {
      this.hasExpandableRows = true;
      this.tableColumns.push('tableArrow');
    }
  }
}
