import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { InfoBoxType } from '@widgets/info-box/info-box.component';

@Component({
  selector: 'eop-wizard-first-page',
  templateUrl: './wizard-first-page.component.html',
  styleUrls: ['./wizard-first-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardFirstPageComponent {
  IconWeight = IconWeight;
  IconSize = IconSize;
  IconColor = IconColor;
  InfoBoxType = InfoBoxType;
  @Input() icon = 'share-outlined';
  @Input() headline = this.translate.instant('GLOBAL.WELCOME_WIZARD');
  @Input() subline: string;
  @Input() mainText: string;
  @Input() notes: string[] | { note: string; type: InfoBoxType; bulletPoints?: string[] }[];

  constructor(private translate: TranslateService) {}
}
