import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FormElementComponent } from '@shared/components/form-element';
import { GroupMemberModule } from '@shared/components/group-member/group-member.module';
import { WidgetModule } from '@widgets/widgets.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ChargingStationAssignmentsTablesComponent } from './components/charging-station-assignments-tables/charging-station-assignments-tables.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterModule } from './components/footer/footer.module';
import { GlobalHeaderComponent } from './components/header/global-header/global-header.component';
import { HeaderModule } from './components/header/header.module';
import { HtmlTooltipComponent } from './components/html-tooltip/html-tooltip.component';
import { ModalBannerComponent } from './components/modal-banner/modal-banner.component';
import { NotificationTopComponent } from './components/notification-top';
import { ScrollToTopComponent } from './components/scroll-to-top';
import { AidNamePipe } from './pipes/aid-name.pipe';
import { PipesModule } from './pipes/pipe.module';
import { TextWithTitleComponent } from '@shared/components/text-with-title/text-with-title.component';
import { WizardFinishBannerComponent } from '@shared/components/wizard-finish-banner/wizard-finish-banner.component';

@NgModule({
  imports: [
    TranslateModule,
    MatTooltipModule,
    InlineSVGModule,
    FooterModule,
    GroupMemberModule,
    HeaderModule,
    WidgetModule,
    FormsModule,
    PipesModule,
    CommonModule,
  ],
  declarations: [
    ChargingStationAssignmentsTablesComponent,
    FormElementComponent,
    ModalBannerComponent,
    NotificationTopComponent,
    ScrollToTopComponent,
    HtmlTooltipComponent,
    TextWithTitleComponent,
    WizardFinishBannerComponent,
  ],
  exports: [
    ChargingStationAssignmentsTablesComponent,
    FooterComponent,
    FormElementComponent,
    GroupMemberModule,
    GlobalHeaderComponent,
    ModalBannerComponent,
    NotificationTopComponent,
    ScrollToTopComponent,
    HtmlTooltipComponent,
    TextWithTitleComponent,
    WizardFinishBannerComponent,
  ],
  providers: [AidNamePipe],
})
export class SharedModule {}
