<div class="wizard-finish-wrapper"
     [ngClass]="{'wizard-finish-wrapper-success': wizardFinishStatus === WizardFinishStatus.SUCCESS,
      'wizard-finish-wrapper-error': wizardFinishStatus === WizardFinishStatus.ERROR}
">
  <eop-icon
    [color]="wizardFinishStatus === WizardFinishStatus.SUCCESS ? IconColor.SUCCESS : IconColor.ERROR"
    [size]="IconSize.MONSTER"
    [vaylensFont]="true"
    [identifier]="'gas-station-outlined'">
  </eop-icon>
  <div class="title-wrapper">
    {{ title }}
  </div>
  <div class="text-wrapper">
    {{ text }}
  </div>
</div>
