<ul
  *ngIf="mode === TabsMode.Tabs"
  [ngClass]="{
    line: line,
    'inner-tab': isInnerTab,
    'tabs-holder--overflow-hidden': hideTextOverflow,
    inline: useInlineFlex,
    'inline-with-scrollbar': useScrollbar
  }"
>
  <li
    *ngFor="let tab of tabs; let tabIndex = index"
    class="tabs"
    [matTooltip]="hideTextOverflow && !shouldTitleCrop ? tab.title : undefined"
    [attr.data-test]="tab.testId ? tab.testId : 'tab' + tabIndex"
    (click)="checkedSelect(activeIndex, tabIndex)"
  >
    <div
      class="tab-button"
      *ngIf="!(tab.disabled && isDisabledAreHidden)"
      [ngClass]="
        (tabIndex === activeIndex ? 'active' : '') +
        ' ' +
        (tab.disabled ? 'tab--disabled' : '') +
        ' ' +
        (tab.showStatus ? 'tab-button--with-status' : '') +
        ' ' +
        (tab.status ? '' + tab.status : '') +
        ' ' +
        (isInnerTab ? 'inner-tab' : '') +
        ' ' +
        (isCondensed ? 'condensed' : '')
      "
      [attr.data-test]="(tab.testId ? tab.testId : 'tab' + tabIndex) + '-button'"
      (click)="onTabClick()"
    >
      <div class="icon-container">
        <eop-icon
          class="icon"
          *ngIf="tab.icon"
          [identifier]="tab.icon"
          [vaylensFont]="tab.vaylensFont"
          [weight]="tab.iconWeight ? tab.iconWeight : IconWeight.BOLD"
          [clickable]="!tab.disabled"
          [color]="tab.disabled ? IconColor.DISABLED : IconColor.INHERIT"
          [size]="tab.vaylensFont ? null : IconSize.SMALL"
          [attr.data-test]="(tab.testId ? tab.testId : 'tab' + tabIndex) + '-icon'"
        >
        </eop-icon>

        <div
          *ngIf="!shouldTitleCrop; else titleCropedTab"
          class="tab-title"
          [attr.data-test]="(tab.testId ? tab.testId : 'tab' + tabIndex) + '-title'"
        >
          {{ tab?.title }}
        </div>
        <ng-template #titleCropedTab>
          <div
            class="tab-title"
            [eopDigitsCrop]="tab?.title"
            [matTooltip]="tab?.title"
            [matTooltipShowDelay]="1000"
            [matTooltipPosition]="'above'"
            [attr.data-test]="(tab.testId ? tab.testId : 'tab' + tabIndex) + '-title'"
          ></div>
        </ng-template>
      </div>
    </div>
  </li>
</ul>

<div *ngIf="mode === TabsMode.Steps" class="steps">
  <div
    *ngFor="let step of tabs; let stepIndex = index"
    class="step"
    (click)="checkedSelect(activeIndex, stepIndex, true)"
  >
    <div
      class="indicator"
      [ngClass]="{
        active: stepIndex === activeIndex,
        disabled: !visitedIndexes.includes(stepIndex) || step.disabled
      }"
    >
      <div class="bubble body-bold-l">{{ stepIndex + 1 }}</div>
      <div class="line"></div>
      <div class="line-right"></div>
    </div>
    <h4>{{ step?.title }}</h4>
  </div>
</div>

<div *ngIf="mode === TabsMode.DynamicSteps" class="steps">
  <div
    *ngFor="let step of tabs; let stepIndex = index"
    class="step"
    (click)="checkedSelect(activeIndex, stepIndex, true)"
  >
    <div
      class="indicator"
      [ngClass]="{
        active: stepIndex === activeIndex,
        disabled: !visitedIndexes.includes(stepIndex) || step.disabled
      }"
    >
      <div class="bubble body-bold-l">{{ stepIndex + 1 }}</div>
      <div class="line"></div>
      <div class="line-right"></div>
    </div>
    <h4>{{ stepIndex === activeIndex ? step?.title : '' }}</h4>
  </div>
</div>
<div [ngClass]="isInnerTab ? 'inner-content' : ''">
  <ng-content></ng-content>
</div>
