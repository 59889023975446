import { Component, Input } from '@angular/core';
import { IconColor, IconSize } from '@widgets/eop-icon';

@Component({
  selector: 'text-with-title',
  templateUrl: 'text-with-title.component.html',
  styleUrls: ['text-with-title.component.scss'],
})
export class TextWithTitleComponent {
  readonly IconSize = IconSize;
  readonly IconColor = IconColor;

  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  iconIdentifier: string;

  @Input()
  tooltip: string;
}
