<div class="info-box {{ type }}" [ngClass]="{ 'with-margin': margin }">
  <eop-icon
    *ngIf="!numberIcon && hasDefaultIcon"
    class="info-icon"
    identifier="info-circle-outlined"
    [vaylensFont]="true"
  ></eop-icon>
  <div *ngIf="numberIcon" class="number-value">
    {{ number }}
  </div>
  <div class="text-wrapper">
    <p *ngIf="biggerText" class="info-text big" [innerHtml]="biggerText"></p>
    <p *ngIf="smallerText" class="info-text small" [innerHtml]="smallerText"></p>
    <div *ngIf="text" class="info-text body-bold-m" [innerHtml]="text"></div>
    <ul *ngIf="bulletPoints" class="detail-list body-bold-m">
      <li *ngFor="let point of bulletPoints" class="detail-item">
        <span> {{ point | translate }} </span>
      </li>
    </ul>
  </div>

  <div class="actions">
    <ng-content select=".info-box-action"></ng-content>
  </div>
</div>
