<div class="header">
  <div class="title heading-m">
    <div class="title-with-tooltip">
      {{ title }}
      <div *ngIf="titleInfoTooltip" class="title-tooltip">
        <eop-icon
          identifier="eoperate-ic-info"
          [tooltip]="titleInfoTooltip"
          [color]="IconColor.DISABLED"
          [vaylensFont]="true"
        ></eop-icon>
      </div>
    </div>
    <div class="results">
      <div class="body-bold-m" *ngIf="showPaginator && !loadingData">
        {{ totalItems | results }}
      </div>
      <div class="body-bold-m" *ngIf="showPaginator && loadingData">
        {{ 'GLOBAL.LOADING_DATA' | translate }}
      </div>
    </div>
  </div>

  <div class="group-controls">
    <div
      *ngIf="searchForText && !(!searchValue && noGroupsLoaded)"
      class="search"
      [class.hidden]="editMode"
    >
      <input
        data-test="group-search"
        type="text"
        #searchphrase
        (keyup)="search($event.target.value, false)"
        [placeholder]="searchForText"
      />
      <eop-icon
        identifier="eoperate-ic-search"
        [vaylensFont]="true"
        [clickable]="searchPossible"
        [size]="IconSize.SMALL"
        [color]="searchPossible ? IconColor.NORMAL : IconColor.DISABLED"
        (click)="searchPossible ? search(searchValue, true) : null"
      ></eop-icon>
    </div>
    <ng-container *ngIf="!editMode && !hideControls && !loadingData">
      <button
        *ngIf="!noGroupsLoaded && sheetVisible"
        eopButton
        [color]="ButtonColor.TERTIARY"
        [iconButton]="true"
        [disabled]="!sheetEnabled"
        [tooltip]="sheetEnabled ? ('GLOBAL.CSV_EXPORT' | translate) : sheetDisabledTooltip"
        [leftIcon]="'export-sq-outlined'"
        [attr.data-test]="'export-sheet-button'"
        (click)="sheetEnabled ? downloadSheet() : null"
      ></button>
      <button
        *ngIf="groups && groups.length > 0"
        eopButton
        [color]="ButtonColor.TERTIARY"
        [leftIcon]="'edit-2-outlined'"
        [attr.data-test]="'toggle-edit-mode'"
        (click)="toggleEditMode()"
      >
        {{ 'GLOBAL.EDIT_MODE' | translate }}
      </button>
      <button
        eopButton
        [color]="ButtonColor.PRIMARY"
        [leftIcon]="'add-outlined'"
        [attr.data-test]="'create-new-group'"
        [disabled]="disableCreate"
        [tooltip]="disableCreate ? disableCreateTooltip : ''"
        (click)="addGroup()"
      >
        {{ (createLabel ? createLabel : 'GLOBAL.CREATE_GROUP') | translate }}
      </button>
    </ng-container>
    <button
      *ngIf="editMode"
      eopButton
      [color]="ButtonColor.OUTLINE"
      [leftIcon]="'logout-1-outlined'"
      [attr.data-test]="'exit-edit-mode'"
      (click)="toggleEditMode()"
    >
      {{ 'GLOBAL.EDIT_MODE_CLOSE' | translate }}
    </button>
  </div>
</div>

<div *ngIf="noGroupsLoaded && !loadingData">
  <div *ngIf="searchValueDebounced; else createGroupPlaceholder">
    <div class="col-4"></div>
    <div class="col-4 group-placeholder">
      <div [forceEvalStyles]="true" [inlineSVG]="'assets/img/a-placeholder-card.svg'"></div>
      <p class="message">
        {{ 'GLOBAL.RESULTS_NONE' | translate }}
      </p>
    </div>
  </div>
  <ng-template #createGroupPlaceholder>
    <div (click)="!hideControls ? addGroup() : null">
      <div class="col-4"></div>
      <div class="col-4 group-placeholder" [ngClass]="!hideControls ? 'active' : ''">
        <div [forceEvalStyles]="true" [inlineSVG]="'assets/img/group-placeholder.svg'"></div>
        <p class="message">
          {{
            (pleaseCreateTooltip ? pleaseCreateTooltip : 'MANAGER.PLEASE_CREATE_GROUP') | translate
          }}
        </p>
      </div>
    </div>
  </ng-template>
</div>

<spinner class="col-12 col-clearfix" *ngIf="loadingData"></spinner>

<eop-grid-list *ngIf="groups && groups.length > 0 && !loadingData">
  <innogy-group
    *ngFor="let group of groups"
    [group]="group"
    [editMode]="editMode"
    [iconDisableMode]="iconDisableMode"
    (editGroupClicked)="editGroup($event)"
    (deleteGroupClicked)="deleteGroup($event)"
    (groupClicked)="selectGroup($event)"
  >
  </innogy-group>
</eop-grid-list>

<eop-pagination
  *ngIf="showPaginator"
  [class.hidden]="loadingData"
  class="col-12"
  [length]="totalItems"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="changePage($event)"
>
</eop-pagination>
