import { NgModule } from '@angular/core';
import { LoadmgtActions } from '@manage/load-management/store/load-management.actions';
import { ChargePortActions } from '@operate/charging-infrastructure-detail/store/actions/charge-port.actions';
import { ChargingInfrastructureDetailActions } from '@operate/charging-infrastructure-detail/store/actions/charging-infrastructure-detail.actions';
import { BackendDataActions, SessionDataActions, UserFilterOptionsActions } from './index';

@NgModule({
  providers: [
    LoadmgtActions,
    SessionDataActions,
    BackendDataActions,
    ChargePortActions,
    UserFilterOptionsActions,
    ChargingInfrastructureDetailActions,
  ],
})
export class ActionsModule {}
