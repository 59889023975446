<div *ngIf="tableConfig.tableSettings.showCounterHeadline && dataSource && dataSource.paging">
  <h4>
    {{
      tableConfig.tableSettings.customCounterHeadline
        ? tableConfig.tableSettings.customCounterHeadline
        : ''
    }}&nbsp;
    <label class="counter-count">
      {{ dataSource.paging.totalItems }} {{ 'GLOBAL.ENTRIES' | translate }}
    </label>
  </h4>
</div>
<div class="clearfix"></div>
<div
  id="tableContainer"
  class="data-table-container"
  [ngStyle]="{
    'min-height': tableConfig.tableSettings.minHeight ? tableConfig.tableSettings.minHeight : null
  }"
>
  <div class="innogy-data-table__searchbar" *ngIf="tableConfig.tableSettings.searchable">
    <input
      data-test="innogy-data-table-search"
      class="box-shadow-1"
      type="text"
      #searchphrase
      (keyup)="requestDataSearch($event.target.value)"
      [ngClass]="!searchVisible ? 'hidden' : ''"
      (blur)="hideSearch()"
      [placeholder]="placeHolderString"
      [matTooltip]="placeHolderTitle"
      [matTooltipShowDelay]="1500"
    />
    <span
      data-test="innogy-data-table-search-icon"
      (click)="toggleSearch()"
      [matTooltip]="'GLOBAL.SEARCH' | translate"
      class="search-icon"
      [ngStyle]="{ position: 'absolute', top: '8px', right: '10px' }"
    >
      <eop-icon
        identifier="filter-search-outlined"
        [vaylensFont]="true"
        [clickable]="true"
      ></eop-icon>
      <span *ngIf="searchInput?.nativeElement?.value" class="search-dot"></span>
    </span>
  </div>
  <table
    #tableElementReference
    data-test="innogy-data-table"
    aria-describedby="innogy-data-table"
    class="data-table"
    [ngClass]="{
      'data-table-scrollable':
        tableConfig.tableSettings.nextDataSelection === 'scroll' && tableConfig.tableSettings.height
    }"
  >
    <thead>
      <tr>
        <th
          id="tableCheckboxHeader"
          class="checkbox-cell"
          *ngIf="tableConfig.tableSettings.checkable"
        >
          <mat-checkbox
            *ngIf="!singleSelectMode"
            (click)="toggleSelectAll()"
            [checked]="hasAllSelected()"
            [indeterminate]="hasSomeSelected()"
            [disableRipple]="true"
            [disabled]="toggleSelectAllDisabled()"
            data-test="check-all"
          >
          </mat-checkbox>
        </th>
        <ng-container *ngFor="let column of displayedColumns">
          <th
            *ngIf="
              column != 'tableScroll' &&
              column != 'tableSelect' &&
              column != 'tableEdit' &&
              column != 'tableDelete' &&
              column != 'tableArrow'
            "
            [ngClass]="{
              sortable:
                tableConfig.tableSettings.sortable &&
                ((sortState.direction == '' && sortState.column == column) ||
                  sortState.column != column) &&
                tableConfig.columns[mapper[column]].sortable,
              'active-sorting-arrow-down':
                tableConfig.tableSettings.sortable &&
                sortState.direction == 'DESC' &&
                sortState.column == column &&
                tableConfig.columns[mapper[column]].sortable,
              'active-sorting-arrow-up':
                tableConfig.tableSettings.sortable &&
                sortState.direction == 'ASC' &&
                sortState.column == column &&
                tableConfig.columns[mapper[column]].sortable
            }"
            [class]="tableConfig.columns[mapper[column]].type"
            [ngStyle]="{ width: tableConfig.columns[mapper[column]].width }"
            (click)="
              tableConfig.tableSettings.sortable && tableConfig.columns[mapper[column]].sortable
                ? requestSort(column)
                : ''
            "
            [class.sort-header]="
              tableConfig.tableSettings.sortable && tableConfig.columns[mapper[column]].sortable
            "
          >
            {{ tableConfig.columns[mapper[column]].value }}
            <eop-icon
              *ngIf="tableConfig.columns[mapper[column]].headerTooltip"
              class="tooltip-icon"
              [weight]="IconWeight.BOLD"
              [size]="IconSize.SMALL"
              [color]="IconColor.DISABLED"
              identifier="info-circle-outlined"
              [vaylensFont]="true"
              [tooltip]="tableConfig.columns[mapper[column]].headerTooltip"
            ></eop-icon>
          </th>
        </ng-container>
        <th
          id="tableCustomActionHeader"
          class="icon-cell"
          *ngIf="tableConfig.tableSettings.customAction"
        >
          &nbsp;
        </th>
        <th
          id="tableCustomAction2Header"
          class="icon-cell"
          *ngIf="tableConfig.tableSettings.customAction2"
        >
          &nbsp;
        </th>
        <th id="tableEditHeader" class="icon-cell" *ngIf="tableConfig.tableSettings.editable">
          &nbsp;
        </th>
        <th id="tableDeleteHeader" class="icon-cell" *ngIf="tableConfig.tableSettings.deletable">
          &nbsp;
        </th>
        <th
          id="tableArrowHeader"
          class="icon-cell"
          *ngIf="tableConfig.tableSettings.expandable || hasExpandableRows"
        >
          &nbsp;
        </th>
        <th
          class="searchableFix"
          *ngIf="
            tableConfig.tableSettings.searchable &&
            (!tableConfig.tableSettings.expandable || !hasExpandableRows)
          "
        ></th>
        <th
          class="tableScrollFix"
          *ngIf="
            tableConfig.tableSettings.nextDataSelection === 'scroll' &&
            tableConfig.tableSettings.height
          "
        >
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody [ngStyle]="tableHeightStyle" (scroll)="onTableScroll($event)" #tableBodyElementReference>
      <ng-container
        *ngIf="
          (dataSource?.data?.length === 0 || dataSource?.rows?.length === 0 || !dataSource) &&
          tableConfig.tableSettings.showEmptyResultsBanner &&
          !contentPlaceholder &&
          !loadingData
        "
      >
        <tr class="tr-no-hover">
          <td class="content-placeholder">
            <eop-image-placeholder
              [image]="'assets/img/a-placeholder-table.svg'"
              [text]="'GLOBAL.NO_ENTRIES' | translate"
            ></eop-image-placeholder>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="contentPlaceholder && !loadingData">
        <tr class="tr-no-hover">
          <td class="content-placeholder">
            <eop-image-placeholder
              [image]="contentPlaceholder.image"
              [text]="contentPlaceholder.text"
            ></eop-image-placeholder>
          </td>
        </tr>
      </ng-container>
      <ng-container
        *ngIf="
          dataSource &&
          !contentPlaceholder &&
          (!loadingData || tableConfig.tableSettings.nextDataSelection === 'scroll')
        "
      >
        <ng-container *ngIf="!useVirtualScroll">
          <ng-container
            *ngFor="let row of rows; let dataIndex = index"
            [ngTemplateOutlet]="tableRowContent"
            [ngTemplateOutletContext]="{ row: row, dataIndex: dataIndex }"
          >
          </ng-container>
        </ng-container>
        <cdk-virtual-scroll-viewport
          class="virtual-scroll"
          *ngIf="useVirtualScroll"
          itemSize="10"
          [ngStyle]="{ height: tableConfig.tableSettings.height }"
        >
          <ng-container
            *cdkVirtualFor="let row of rows; let dataIndex = index"
            [ngTemplateOutlet]="tableRowContent"
            [ngTemplateOutletContext]="{ row: row, dataIndex: dataIndex }"
          >
          </ng-container>
        </cdk-virtual-scroll-viewport>
        <ng-template #tableRowContent let-row="row" let-dataIndex="dataIndex">
          <tr
            class="data-row highlighted-{{ row.highlightedColor }}"
            [class.expanded]="expandedElement != null && expandedElement == dataIndex"
            [class.collapsed]="expandedElement == null || expandedElement != dataIndex"
            [class.checked-row]="isSelected(row)"
            [class.checkbox-disabled]="row.disabled"
            (click)="onRowClicked(row, dataIndex)"
            [attr.data-test]="tableConfig.tableSettings.testId + '-row-' + dataIndex"
            [matTooltip]="
              tableConfig.tableSettings.checkable &&
              row.disabled &&
              tableConfig.tableSettings.disabledCheckboxTooltip
                ? tableConfig.tableSettings.disabledCheckboxTooltip
                : null
            "
          >
            <td *ngIf="tableConfig.tableSettings.checkable" class="checkbox-cell">
              <mat-checkbox
                *ngIf="!singleSelectMode"
                (click)="$event.stopPropagation(); toggleSelect(row)"
                [checked]="isSelected(row)"
                [disableRipple]="true"
                [disabled]="toggleSelectDisabled(row) || row.disabled"
              >
              </mat-checkbox>
              <mat-radio-button
                *ngIf="singleSelectMode"
                (click)="$event.stopPropagation(); toggleSelect(row)"
                [checked]="isSelected(row)"
                [disableRipple]="true"
                [disabled]="row.disabled"
              >
              </mat-radio-button>
            </td>
            <ng-container *ngFor="let column of displayedColumns">
              <td
                *ngIf="
                  column != 'tableScroll' &&
                  column != 'tableSelect' &&
                  column != 'tableEdit' &&
                  column != 'tableDelete' &&
                  column != 'tableArrow'
                "
                [ngStyle]="{
                  width: tableConfig.columns[mapper[column]].width,
                  color: row.data.fontColor,
                  opacity: row.disabled ? 0.5 : 1
                }"
                [class]="tableConfig.columns[mapper[column]].type"
              >
                <ng-container [ngSwitch]="tableConfig.columns[mapper[column]].type">
                  <ng-template ngSwitchCase="link">
                    <link-cell
                      class="pointy"
                      [text]="row.data[column].text"
                      [id]="tableConfig.columns[mapper[column]].id"
                      [trackingCategory]="tableConfig.tableSettings.trackingCategory"
                      [showTooltip]="row.data[column]['showTooltip']"
                      [eventUrl]="row.data[column]['url'] ? row.data[column]['url'] : null"
                      [icon]="row.data[column]['icon'] ? row.data[column]['icon'] : null"
                      [shouldTextCrop]="tableConfig.columns[mapper[column]].shouldTextCrop"
                      [highlightedColor]="row.highlightedColor"
                    ></link-cell>
                  </ng-template>
                  <ng-template ngSwitchCase="doubleLink">
                    <div>
                      <link-cell
                        [ngClass]="{ pointy: !!row.data[column][0].url }"
                        [text]="row.data[column][0].text | empty"
                        [showTooltip]="row.data[column][0].showTooltip"
                        [eventUrl]="row.data[column][0].url ? row.data[column][0].url : null"
                        [icon]="row.data[column][0].icon ? row.data[column][0].icon : null"
                        [shouldTextCrop]="row.data[column].shouldTextCrop"
                      ></link-cell>
                      <link-cell
                        *ngIf="row.data[column][1]"
                        [ngClass]="{ pointy: !!row.data[column][1].url }"
                        [text]="row.data[column][1].text | empty"
                        [showTooltip]="row.data[column][1].showTooltip"
                        [eventUrl]="row.data[column][1].url ? row.data[column][1].url : null"
                        [icon]="row.data[column][1].icon ? row.data[column][1].icon : null"
                        [shouldTextCrop]="row.data[column].shouldTextCrop"
                      ></link-cell>
                    </div>
                  </ng-template>
                  <ng-template ngSwitchCase="icon">
                    <icon-cell [icons]="row.data[column]"></icon-cell>
                  </ng-template>
                  <ng-template ngSwitchCase="toggle">
                    <div
                      class="toggle-wrapper"
                      *ngIf="row.data[column]"
                      [matTooltip]="row.data[column]['tooltip']"
                    >
                      <ng-container *ngIf="setToggleState(row.data[column]['toggleState'])">
                        <label class="switch">
                          <input
                            [ngClass]="row.data[column]['grayed'] ? 'grayed' : ''"
                            type="checkbox"
                            [checked]="toggleState == 'ACTIVE'"
                            [disabled]="row.data[column]['grayed'] || row.data[column]['disabled']"
                            (change)="toggle(row)"
                          />
                          <span class="slider round"></span>
                        </label>
                      </ng-container>
                      <ng-container *ngIf="row.data[column]['toggleState'] == 'DISABLED'">
                        <img
                          class="icon"
                          src="/assets/img/a-toggle-switch-disabled.svg"
                          (click)="$event.stopPropagation()"
                          alt="toggle switch"
                        />
                      </ng-container>
                    </div>
                  </ng-template>
                  <ng-template ngSwitchCase="endpoint-icon">
                    <div
                      *ngIf="row.data['transferData']['withForeignHubjectChargingPoints']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.INTERCHARGE_EVERYWHERE' | translate"
                      id="hubject"
                    >
                      <eop-icon identifier="hubject" [weight]="IconWeight.BOLD"></eop-icon>
                    </div>
                    <div
                      *ngIf="row.data['transferData']['withPublicChargingPoints']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.PUBLIC_CHARGE_PORTS_AVAILABLE' | translate"
                    >
                      <eop-icon identifier="database-share_1"></eop-icon>
                    </div>
                    <div
                      *ngIf="
                        row.data['transferData']['cpoList'] &&
                        row.data['transferData']['cpoList'].length
                      "
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.PRIVATE_CHARGE_PORTS_AVAILABLE' | translate"
                    >
                      <eop-icon identifier="database-lock"></eop-icon>
                    </div>
                    <div
                      *ngIf="row.data['transferData']['withStatusUpdate']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.STATUS_UPDATE_BOOKED' | translate"
                    >
                      <eop-icon identifier="database-refresh"></eop-icon>
                    </div>
                  </ng-template>
                  <ng-template ngSwitchCase="endpoint-icon-v2">
                    <div
                      *ngIf="row.data['iconConfig']['withPublicChargingPoints']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.PUBLIC_CHARGE_PORTS_AVAILABLE' | translate"
                    >
                      <eop-icon identifier="POI-public" [weight]="IconWeight.REGULAR"></eop-icon>
                    </div>
                    <div
                      *ngIf="row.data['iconConfig']['poiGroup']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.EMP_AND_EMAID_SELECTED' | translate"
                    >
                      <eop-icon identifier="POI-group" [weight]="IconWeight.REGULAR"></eop-icon>
                    </div>
                    <div
                      *ngIf="row.data['iconConfig']['cpoSelected']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.CPOS_SELECTED' | translate"
                    >
                      <eop-icon identifier="POI-list" [weight]="IconWeight.REGULAR"></eop-icon>
                    </div>
                    <div
                      *ngIf="row.data['iconConfig']['dynamicStatusPushEnabled']"
                      class="multiple-icon-cell vertical-middle"
                      [matTooltip]="'WEBSERVICES.STATUS_UPDATE_BOOKED' | translate"
                    >
                      <eop-icon identifier="POI-dynamic" [weight]="IconWeight.REGULAR"></eop-icon>
                    </div>
                  </ng-template>
                  <ng-template ngSwitchCase="duration">
                    {{ row.data[column] | userDateTime : false : true }}
                  </ng-template>
                  <ng-template ngSwitchCase="dateTime">
                    {{
                      date.parse(row.data[column])
                        ? (row.data[column]
                          | userDateTime : tableConfig.columns[mapper[column]].adaptUserTimeZone)
                        : row.data[column]
                    }}
                  </ng-template>
                  <ng-template ngSwitchCase="date">
                    {{
                      date.parse(row.data[column])
                        ? (row.data[column]
                          | userDate : tableConfig.columns[mapper[column]].adaptUserTimeZone)
                        : row.data[column]
                    }}
                  </ng-template>
                  <ng-template ngSwitchCase="time">
                    {{
                      date.parse('1999-09-09T' + row.data[column])
                        ? (row.data[column]
                          | userTimeUtcOffset : tableConfig.columns[mapper[column]].format)
                        : row.data[column]
                    }}
                  </ng-template>
                  <ng-template ngSwitchCase="boolean">
                    <boolean-cell [cellData]="row.data[column]"></boolean-cell>
                  </ng-template>
                  <ng-template ngSwitchCase="status">
                    <status-cell [cellData]="row.data[column]"></status-cell>
                  </ng-template>
                  <ng-template ngSwitchCase="list">
                    <span *ngIf="row.data[column].length === 0; else moreListItems"></span>
                    <ng-template #moreListItems>
                      <div class="item-list-container">
                        <div class="item-list-text" [matTooltip]="row.data[column][0]">
                          {{ row.data[column][0] }}
                        </div>
                        <eop-innogy-chips-lite
                          *ngIf="row.data[column].length > 1"
                          [text]="'+' + (row.data[column].length - 1)"
                          [chipColor]="ChipLiteColor.INFO"
                          [chipSize]="ChipLiteSize.MEDIUM"
                          [matTooltip]="row.data[column].slice(1).join('\n')"
                          matTooltipClass="allow-cr"
                        >
                        </eop-innogy-chips-lite>
                      </div>
                    </ng-template>
                  </ng-template>
                  <ng-template ngSwitchCase="chips">
                    <app-innogy-chips
                      [chipColor]="
                        tableConfig.columns[mapper[column]].chipSettings &&
                        tableConfig.columns[mapper[column]].chipSettings.chipColor
                          ? tableConfig.columns[mapper[column]].chipSettings.chipColor
                          : chipsColor
                      "
                      [chipsData]="row.data[column]"
                      [maxChipTextLength]="
                        tableConfig.columns[mapper[column]].chipSettings &&
                        tableConfig.columns[mapper[column]].chipSettings.chipTextMaxLength
                          ? tableConfig.columns[mapper[column]].chipSettings.chipTextMaxLength
                          : 0
                      "
                      [chipListCollapsable]="
                        tableConfig.columns[mapper[column]].chipSettings &&
                        tableConfig.columns[mapper[column]].chipSettings.chipListCollapsable
                      "
                      [emphasized]="
                        tableConfig.columns[mapper[column]].chipSettings &&
                        tableConfig.columns[mapper[column]].chipSettings.chipEmphasized
                      "
                    >
                    </app-innogy-chips>
                  </ng-template>
                  <ng-template ngSwitchCase="chipsLite">
                    <eop-innogy-chips-lite
                      *ngIf="
                        row.data[column]?.chipColor &&
                          !tableConfig.columns[mapper[column]]?.chipSettings?.chipColor;
                        else colorFromConfig
                      "
                      [chipColor]="row.data[column].chipColor"
                      [chipSize]="ChipLiteSize.MEDIUM"
                      [text]="row.data[column].text"
                      [title]="row.data[column].tooltip"
                      [testId]="'chip-no-' + dataIndex"
                      [enableTitleTooltip]="tableConfig.columns[mapper[column]].showToolTip"
                    >
                    </eop-innogy-chips-lite>
                    <ng-template #colorFromConfig>
                      <eop-innogy-chips-lite
                        *ngIf="row.data[column] !== null; else empty"
                        [chipColor]="
                          row.data[column]?.chipColor
                            ? tableConfig.columns[mapper[column]].chipSettings.chipColor
                            : null
                        "
                        [chipSize]="ChipLiteSize.MEDIUM"
                        [text]="row.data[column].text"
                        [enableTitleTooltip]="true"
                        [testId]="'chip-no-' + dataIndex"
                      >
                      </eop-innogy-chips-lite>
                    </ng-template>
                    <ng-template #empty>
                      <div *ngIf="row.data[column] === null">-</div>
                    </ng-template>
                  </ng-template>
                  <ng-template ngSwitchCase="chipsLiteList">
                    <eop-innogy-chips-lite
                      *ngFor="let chip of row.data[column]"
                      [chipColor]="chip.chipColor"
                      [chipSize]="ChipLiteSize.MEDIUM"
                      [text]="chip.text"
                      [matTooltip]="chip.title ? chip.title : undefined"
                      [enableTitleTooltip]="true"
                      [testId]="'chip-no-' + dataIndex"
                    >
                    </eop-innogy-chips-lite>
                  </ng-template>
                  <ng-template ngSwitchCase="html">
                    <div
                      [ngClass]="{
                        'max-row-height': tableConfig.columns[mapper[column]].limitHeight,
                        'word-break': tableConfig.columns[mapper[column]].showToolTip
                          ? ''
                          : 'break-word'
                      }"
                      [innerHTML]="row.data[column]"
                    ></div>
                  </ng-template>
                  <ng-template ngSwitchCase="textWithTooltip">
                    <div
                      [ngClass]="{
                        'max-row-height': tableConfig.columns[mapper[column]].limitHeight,
                        'word-break': tableConfig.columns[mapper[column]].showToolTip
                          ? ''
                          : 'break-word'
                      }"
                      [matTooltip]="row.data[column]?.tooltip"
                      [matTooltipPositionAtOrigin]="true"
                      [innerText]="row.data[column]?.text"
                    ></div>
                  </ng-template>
                  <ng-template ngSwitchCase="textWithChip">
                    <span>{{ row.data[column].text }}</span>
                    <eop-innogy-chips-lite
                      *ngIf="row.data[column].chipText"
                      [chipColor]="row.data[column].chipColor"
                      [chipSize]="ChipLiteSize.MEDIUM"
                      [text]="row.data[column].chipText | translate"
                    >
                    </eop-innogy-chips-lite>
                  </ng-template>
                  <ng-template ngSwitchCase="input">
                    <input
                      type="text"
                      [attr.data-test]="'input-' + dataIndex"
                      [placeholder]="tableConfig.columns[mapper[column]].inputPlaceholder"
                      [maxlength]="tableConfig.columns[mapper[column]].maxInputLength"
                      [(ngModel)]="row.data[column]"
                    />
                  </ng-template>
                  <ng-template ngSwitchCase="inputWithEdit">
                    <eop-input-edit-mode
                      type="text"
                      [id]="row.id"
                      [testId]="'input-' + dataIndex"
                      [placeholder]="tableConfig.columns[mapper[column]].inputPlaceholder"
                      [value]="row.data[column]"
                      [validators]="tableConfig.columns[mapper[column]].validators"
                      (saveClicked)="onInputChanged(row, $event)"
                    ></eop-input-edit-mode>
                  </ng-template>
                  <ng-template ngSwitchDefault>
                    <span
                      *ngIf="
                        tableConfig.columns[mapper[column]].showToolTip &&
                        !tableConfig.columns[mapper[column]].shouldTextCrop
                      "
                      [ngStyle]="{
                        'white-space': 'nowrap',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'word-break': tableConfig.columns[mapper[column]].showToolTip
                          ? ''
                          : 'break-word'
                      }"
                      [matTooltip]="row.data[column]"
                      >{{ row.data[column] }}</span
                    >
                    <span
                      *ngIf="tableConfig.columns[mapper[column]].shouldTextCrop"
                      [ngStyle]="{
                        'white-space': 'nowrap',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'word-break': tableConfig.columns[mapper[column]].showToolTip
                          ? ''
                          : 'break-word'
                      }"
                      [eopDigitsCrop]="row.data[column]"
                    ></span>
                    <span
                      *ngIf="
                        !tableConfig.columns[mapper[column]].showToolTip &&
                        !tableConfig.columns[mapper[column]].shouldTextCrop
                      "
                      [ngStyle]="{
                        'word-break': 'break-word'
                      }"
                      >{{ row.data[column] }}</span
                    >
                  </ng-template>
                </ng-container>
              </td>
            </ng-container>
            <td *ngIf="tableConfig.tableSettings.customAction2" class="icon-cell">
              <div
                class="vertical-middle"
                *ngIf="
                  !row.data['hideCustomAction2Icon'] &&
                  (row.data['customAction2Enabled'] == null ||
                    row.data['customAction2Enabled'] === true)
                "
                (click)="$event.stopPropagation(); customAction2DataSource(row)"
                [matTooltip]="
                  (tableConfig.tableSettings.customAction2Label | translate) ||
                  row.data['customAction2Label']
                "
              >
                <eop-icon
                  [identifier]="tableConfig.tableSettings.customAction2Icon"
                  [vaylensFont]="tableConfig.tableSettings.vaylensFont"
                  [weight]="tableConfig.tableSettings.customAction2IconWeight"
                  [clickable]="true"
                  [dataTestId]="'custom2-' + dataIndex"
                ></eop-icon>
              </div>
              <div *ngIf="row.data['customAction2Enabled'] === false" class="vertical-middle">
                <eop-icon
                  [identifier]="tableConfig.tableSettings.customAction2Icon"
                  [vaylensFont]="tableConfig.tableSettings.vaylensFont"
                  [weight]="tableConfig.tableSettings.customAction2IconWeight"
                  [color]="IconColor.DISABLED"
                  [tooltip]="row.data['customAction2NotEnabledTooltip']"
                  [dataTestId]="'custom2-disabled-' + dataIndex"
                ></eop-icon>
              </div>
            </td>
            <td *ngIf="tableConfig.tableSettings.customAction" class="icon-cell">
              <div
                class="vertical-middle"
                *ngIf="
                  !row.data['hideCustomActionIcon'] &&
                  (row.data['customActionEnabled'] == null ||
                    row.data['customActionEnabled'] === true)
                "
                (click)="$event.stopPropagation(); customActionDataSource(row)"
                [matTooltip]="
                  (tableConfig.tableSettings.customActionLabel | translate) ||
                  row.data['customActionLabel']
                "
              >
                <eop-icon
                  [identifier]="tableConfig.tableSettings.customActionIcon"
                  [vaylensFont]="tableConfig.tableSettings.vaylensFont"
                  [weight]="tableConfig.tableSettings.customActionIconWeight"
                  [clickable]="true"
                  [dataTestId]="'custom' + dataIndex"
                ></eop-icon>
              </div>
              <div *ngIf="row.data['customActionEnabled'] === false" class="vertical-middle">
                <eop-icon
                  [identifier]="tableConfig.tableSettings.customActionIcon"
                  [vaylensFont]="tableConfig.tableSettings.vaylensFont"
                  [weight]="tableConfig.tableSettings.customActionIconWeight"
                  [color]="IconColor.DISABLED"
                  [tooltip]="row.data['customActionNotEnabledTooltip']"
                  [dataTestId]="'custom-disabled-' + dataIndex"
                ></eop-icon>
              </div>
            </td>
            <td *ngIf="tableConfig.tableSettings.editable" class="icon-cell">
              <div
                class="vertical-middle"
                *ngIf="row.data['editable'] == null || row.data['editable'] === true"
                (click)="$event.stopPropagation(); editDataSource(row)"
                [matTooltip]="'GLOBAL.EDIT' | translate"
              >
                <eop-icon
                  identifier="edit-2-outlined"
                  [vaylensFont]="true"
                  [clickable]="true"
                  [dataTestId]="'edit' + dataIndex"
                  [label]="'GLOBAL.EDIT' | translate"
                ></eop-icon>
              </div>
              <div *ngIf="row.data['editable'] === false" class="vertical-middle">
                <eop-icon
                  identifier="edit-2-outlined"
                  [vaylensFont]="true"
                  [color]="IconColor.DISABLED"
                  [tooltip]="row.data['notEditableTooltip']"
                ></eop-icon>
              </div>
            </td>
            <td *ngIf="tableConfig.tableSettings.deletable" class="icon-cell">
              <div
                class="vertical-middle"
                *ngIf="row.data['deletable'] == null || row.data['deletable'] === true"
                (click)="$event.stopPropagation(); deleteDataSource(row)"
                [matTooltip]="'GLOBAL.DELETE' | translate"
              >
                <eop-icon
                  class="delete-active"
                  identifier="trash-outlined"
                  [vaylensFont]="true"
                  [color]="IconColor.ERROR"
                  [clickable]="true"
                  [dataTestId]="'delete' + dataIndex"
                ></eop-icon>
              </div>
              <div *ngIf="row.data['deletable'] === false" class="vertical-middle">
                <eop-icon
                  class="delete-inactive"
                  identifier="trash-outlined"
                  [vaylensFont]="true"
                  [color]="IconColor.DISABLED"
                  [tooltip]="row.data['notDeletableTooltip']"
                  [dataTestId]="'delete' + dataIndex"
                  [attr.aria-disabled]="true"
                ></eop-icon>
              </div>
            </td>
            <td *ngIf="tableConfig.tableSettings.expandable || hasExpandableRows" class="icon-cell">
              <expanding-arrow-cell
                *ngIf="row.expandable || tableConfig.tableSettings.expandable"
                [cellData]="dataIndex"
                [expansionRowExpanded]="expandedElement"
              ></expanding-arrow-cell>
            </td>
            <td
              *ngIf="
                tableConfig.tableSettings.searchable &&
                !tableConfig.tableSettings.expandable &&
                !hasExpandableRows
              "
              class="searchableFix"
            ></td>
          </tr>
          <ng-container *ngIf="tableConfig.tableSettings.expandable || row.expandable">
            <ng-container
              [ngTemplateOutlet]="
                detailListExpandableRow
                  ? detailListExpandableRowTemplate
                  : defaultExpandableRowTemplate
              "
              [ngTemplateOutletContext]="{ row: row, dataIndex: dataIndex }"
            >
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>
      <tr class="tr-no-hover" *ngIf="loadingData">
        <td><spinner></spinner></td>
      </tr>
    </tbody>
  </table>
  <ng-container
    *ngIf="
      (dataSource?.data?.length > 0 || dataSource?.rows?.length > 0) &&
      nextDataSelection === 'scroll' &&
      tableConfig?.tableSettings &&
      !tableConfig.tableSettings.height
    "
  >
    <div class="infinite-scroll" snInViewport (inViewportChange)="onViewScroll($event)"></div>
  </ng-container>
  <eop-pagination
    *ngIf="
      (dataSource?.data?.length > 0 || dataSource?.rows?.length > 0) &&
      nextDataSelection == 'paginator'
    "
    #paginator
    [class]="{ hide: loadingData }"
    [length]="dataSource?.paging?.totalItems"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPaginateChange($event)"
  ></eop-pagination>
  <app-innogy-chips
    *ngIf="tableConfig.tableSettings.showChipList"
    [attr.data-loading]="loadingData"
    [chipsData]="chipsData"
    [chipsRemovable]="true"
    [showChipsHeadline]="chipsHeadline ? true : false"
    [customChipsHeadline]="chipsHeadline"
    [chipColor]="chipsColor"
    [chipKey]="'value'"
    [chipName]="'value'"
    [chipListCollapsable]="true"
    (chipRemoved)="removeChip($event)"
    [shouldChipTextCrop]="tableConfig?.tableSettings?.shouldChipTextCrop"
  ></app-innogy-chips>
</div>
<div class="clearfix"></div>

<ng-template #defaultExpandableRowTemplate let-row="row" let-dataIndex="dataIndex">
  <tr
    [id]="'expandedDetail'"
    class="tr-no-hover"
    [class.expanded-row]="expandedElement == dataIndex"
    [ngClass]="expandedElement == dataIndex ? '' : 'no-border'"
  >
    <td
      [attr.colspan]="displayedColumns.length - 1"
      class="no-padding"
      *ngIf="expandedElement == dataIndex"
    >
      <div
        class="expanding-row-animation"
        [@detailExpand]="expandedElement == dataIndex ? 'expanded' : 'collapsed'"
      >
        <ng-container>
          <innogy-box
            [margin]="false"
            [smallHeader]="true"
            [padding]="
              tableConfig.tableSettings.expansionPadding === undefined ||
              tableConfig.tableSettings.expansionPadding === true
            "
          >
            <div class="innerTable">
              <ng-template #content>
                <ng-content></ng-content>
              </ng-template>
              <div *ngIf="dataIndex == expandedElement">
                <ng-container *ngTemplateOutlet="content"></ng-container>
              </div>
              <div class="innerTable--boxes clearfix">
                <div class="col-4 min-height-100" *ngFor="let detail of detailColumns">
                  <div class="label" *ngIf="tableConfig.columns[mapper[detail]].visibleInDetails">
                    {{ tableConfig.columns[mapper[detail]].value }}
                  </div>
                  <div class="content">
                    <ng-container
                      *ngIf="tableConfig.columns[mapper[detail]].visibleInDetails"
                      [ngTemplateOutlet]="tableCell"
                      [ngTemplateOutletContext]="{
                        cellDetails: tableConfig.columns[mapper[detail]],
                        cellData: row.data[detail]
                      }"
                      class="body-l"
                    >
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </innogy-box>
        </ng-container>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #detailListExpandableRowTemplate let-row="row" let-dataIndex="dataIndex">
  <tr *ngIf="expandedElement == dataIndex" [class.expanded-row]="expandedElement == dataIndex">
    <td
      *ngFor="let detail of detailColumns"
      [class.detail-list-expanding-row]="expandedElement == dataIndex"
    >
      <div [@detailExpand]="expandedElement == dataIndex ? 'expanded' : 'collapsed'">
        <div *ngIf="tableConfig.columns[mapper[detail]].visibleInDetails" class="label">
          {{ tableConfig.columns[mapper[detail]].value }}
        </div>
        <div class="content body-l">
          <ng-container
            *ngIf="tableConfig.columns[mapper[detail]].visibleInDetails"
            [ngTemplateOutlet]="tableCell"
            [ngTemplateOutletContext]="{
              cellDetails: tableConfig.columns[mapper[detail]],
              cellData: row.data[detail]
            }"
          >
          </ng-container>
        </div>
      </div>
    </td>
    <td *ngIf="tableConfig.tableSettings.customAction2" class="icon-cell"></td>
    <td *ngIf="tableConfig.tableSettings.customAction" class="icon-cell"></td>
    <td *ngIf="tableConfig.tableSettings.editable" class="icon-cell"></td>
    <td *ngIf="tableConfig.tableSettings.deletable" class="icon-cell"></td>
    <td *ngIf="tableConfig.tableSettings.expandable || hasExpandableRows" class="icon-cell"></td>
  </tr>
</ng-template>

<ng-template #tableCell let-cellDetails="cellDetails" let-cellData="cellData">
  <ng-container [ngSwitch]="cellDetails.type">
    <ng-template ngSwitchCase="list">
      <ul>
        <li *ngFor="let item of cellData">{{ item }}</li>
      </ul>
    </ng-template>
    <ng-template ngSwitchCase="text">
      <span *ngIf="!cellDetails.shouldTextCrop">{{ cellData | empty }}</span>
      <span
        *ngIf="cellDetails.shouldTextCrop"
        [eopDigitsCrop]="cellData | empty"
        [matTooltip]="cellData"
        [matTooltipPositionAtOrigin]="true"
      ></span>
    </ng-template>
    <ng-template ngSwitchCase="duration">
      {{ cellData | userDateTime : false : true | empty }}
    </ng-template>
    <ng-template ngSwitchCase="dateTime">
      {{ cellData | userDateTime : cellDetails.adaptUserTimeZone | empty }}
    </ng-template>
    <ng-template ngSwitchCase="date">
      {{ cellData | userDate : cellDetails.adaptUserTimeZone | empty }}
    </ng-template>
    <ng-template ngSwitchCase="time">
      {{ cellData | userTimeUtcOffset : cellDetails.format | empty }}
    </ng-template>
    <ng-template ngSwitchCase="link">
      <link-cell
        [ngClass]="{ pointy: !!cellData.url }"
        [text]="cellData.text | empty"
        [showTooltip]="cellData.showTooltip"
        [eventUrl]="cellData.url ? cellData.url : null"
        [icon]="cellData.icon ? cellData.icon : null"
        [shouldTextCrop]="cellDetails.shouldTextCrop"
      ></link-cell>
    </ng-template>
    <ng-template ngSwitchCase="icon">
      {{ cellData | empty }}
      <icon-cell [icons]="cellData"></icon-cell>
    </ng-template>
    <ng-template ngSwitchCase="chips">
      <app-innogy-chips
        [chipsData]="cellData"
        [chipColor]="cellDetails.chipColor ? cellDetails.chipColor : chipsColor"
      ></app-innogy-chips>
    </ng-template>
    <ng-template ngSwitchCase="chipsLite">
      <eop-innogy-chips-lite
        *ngIf="cellData.text !== ''; else empty"
        [chipColor]="cellDetails.chipSettings ? cellDetails.chipSettings.chipColor : null"
        [chipSize]="ChipLiteSize.MEDIUM"
        [text]="cellData.text"
        [enableTitleTooltip]="true"
      ></eop-innogy-chips-lite>
      <ng-template #empty>
        {{ cellData.text | empty }}
      </ng-template>
    </ng-template>
    <ng-template ngSwitchCase="boolean">
      <boolean-cell [cellData]="cellData | empty"></boolean-cell>
    </ng-template>
    <ng-template ngSwitchCase="html">
      <span [innerHTML]="cellData | empty"></span>
    </ng-template>
    <ng-template ngSwitchCase="location">
      <location-cell [location]="cellData"> </location-cell>
    </ng-template>
    <ng-template ngSwitchCase="bullet-status">
      <bullet-status-cell [cellData]="cellData"></bullet-status-cell>
    </ng-template>
    <ng-template ngSwitchCase="template">
      <ng-container
        [ngTemplateOutlet]="tableDetailTemplate"
        [ngTemplateOutletContext]="{ templateItem: cellData }"
      >
      </ng-container>
    </ng-template>
    <ng-template ngSwitchDefault>
      {{ cellData }}
    </ng-template>
  </ng-container>
</ng-template>
