import { EDetailedStatusEnum, EStatusEnum } from '@shared/services/util';

export interface MapConfig {
  POIs: POI[];
  centerIcon?: string;
}

export interface POI {
  type: PoiType;
  coordinate?: Coordinate;
  info?: PoiInfo;
  chargePoints?: PoiChargePoint[];
  customData?: any;
}

export interface PoiChargePoint {
  name: string;
  status: EStatusEnum;
  detailStatus: EDetailedStatusEnum;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export enum PoiType {
  TERMINAL = 'TERMINAL',
  STANDALONE_TERMINAL = 'STANDALONE_TERMINAL',
  STATION = 'STATION',
  GROUP = 'GROUP',
  ADDRESS = 'ADDRESS',
  XPAY = 'XPAY',
}

export interface PoiInfo {
  id: string;
  uuid: string;
  address?: string[];
  country?: string;
  status?: PoiStatus;
  isInternal?: boolean;
}

export enum PoiStatus {
  BRAND = 'brandColor',
  DISABLED = 'disabledColor',
  ERROR = 'errorColor',
  INFO = 'infoColor',
  SUCCESS = 'successColor',
  WARNING = 'warningColor',
}

export enum AdaptViewType {
  PAN_TO_CENTER_MANUALY,
  PAN_TO_CENTER_BY_POINTS,
  FIT_BOUNDS,
  USERS_GEO_POSITION,
}

export interface MapPosition {
  lng: number;
  lat: number;
}

export interface CenterToPoiButton {
  iconPath: string;
  title: string;
  centerToPoiIconWidth: number;
}
